<template>
  <div v-if="detail">
    <b-row
      id="detail-prestazione-row"
      align-v="stretch"
      class="mt-n4 flex-wrap"
    >
      <b-col
        class="col-12 col-md-4 col-lg-3 col-xl-2 d-flex flex-column justify-content-start px-0"
      >
        <div class="m-0 p-0 detail-modal-card p-3 mr-4 mt-4 h-100">
          <p class="font-weight-bolder detail-modal-card-titles">
            DURATA PREVISTA
          </p>
          <p>{{ detail.durata }} minuti</p>
        </div>
      </b-col>
      <b-col
        class="col-12 col-md-4 col-lg-3 col-xl-2 d-flex flex-column justify-content-start px-0"
        v-if="stato == 4"
      >
        <div class="m-0 p-0 detail-modal-card p-3 mr-4 mt-4 h-100">
          <p class="font-weight-bolder detail-modal-card-titles">
            DURATA EFFETTIVA
          </p>
          <p>{{ detail.durata_effettiva }} minuti</p>
        </div>
      </b-col>
      <b-col
        class="col-12 col-md-4 col-lg-3 col-xl-2 d-flex flex-column justify-content-start px-0"
        v-if="survey !== null && survey.response"
      >
        <div class="m-0 p-0 detail-modal-card p-3 mr-4 mt-4 h-100">
          <p class="font-weight-bolder detail-modal-card-titles">
            QUESTIONARIO
          </p>
          <p class="m-0 mt-n2 p-0 text-capitalize">
            <b-button
              variant="outline-primary  "
              size="sm"
              class="px-2 mr-2 mt-2 w-100"
              @click="$refs['show-filleditem'].show()"
              >apri questionario</b-button
            >
            <b-button
              variant="outline-secondary"
              size="sm"
              class="px-2 mx-0 mt-2 w-100"
              @click="downloadOne"
              >scarica in formato pdf</b-button
            >
          </p>
        </div>
      </b-col>
      <b-col
        class="col-12 col-md-4 col-lg-3 col-xl-2 d-flex flex-column justify-content-start px-0"
        v-if="suspendedSurvey"
      >
        <div class="m-0 p-0 detail-modal-card p-3 mr-4 mt-4 h-100">
          <p class="font-weight-bolder detail-modal-card-titles">
            QUESTIONARIO DA COMPILARE
          </p>
          <p class="m-0 p-0 text-capitalize">
            <b-button
              variant="outline-primary"
              size="sm"
              @click="
                $router.push({
                  name: 'completa questionario',
                  params: { quest: suspendedSurvey.hashid }
                })
              "
              ><span class="mx-1">Completa questionario</span>
              <b-icon icon="patch-exclamation-fill"></b-icon>
            </b-button>
          </p>
        </div>
      </b-col>
      <b-col
        v-if="stato === 2 || stato === 3"
        class="col-12 col-md-4 col-lg-3 col-xl-2 d-flex flex-column justify-content-start px-0"
      >
        <div class="m-0 p-0 detail-modal-card p-3 mr-4 mt-4 h-100">
          <p class="font-weight-bolder detail-modal-card-titles">
            TELECONSULTO
          </p>
          <p class="m-0 p-0 text-capitalize">
            <b-button
              size="sm"
              @click="
                $router.push({
                  name: 'il tuo consulto',
                  query: { reserv: hash }
                })
              "
              type="button"
              variant="outline-primary mr-0 ml-auto"
              >vai al Teleconsulto
            </b-button>
          </p>
        </div>
      </b-col>
      <b-col
        v-if="company_notes"
        class="col-12 col-md-4 col-lg-3 col-xl-2 d-flex flex-column justify-content-start px-0"
      >
        <div class="m-0 p-0 detail-modal-card p-3 mr-4 mt-4 h-100">
          <p class="font-weight-bolder detail-modal-card-titles">
            NOTE DELL'OPERATORE
          </p>
          <p>{{ company_notes }}</p>
        </div>
      </b-col>
      <b-col
        v-if="detail.details"
        class="col-12 col-md-4 col-lg-3 col-xl-2 d-flex flex-column justify-content-start px-0"
      >
        <div class="m-0 p-0 detail-modal-card p-3 mr-4 mt-4 h-100">
          <p class="font-weight-bolder detail-modal-card-titles">NOTE</p>
          <p>{{ detail.details }}</p>
        </div>
      </b-col>
      <b-col
        v-if="
          Math.abs(stato) !== 4 && (editBtn || stato === 5 || checkValidity)
        "
        class="col-12 col-md-4 col-lg-3 col-xl-2 d-flex flex-column justify-content-start px-0"
      >
        <div class="m-0 p-0 p-3 mr-4 mt-4 h-100">
          <b-button
            v-if="editBtn"
            size="sm"
            @click="updateRequest"
            type="button"
            variant="info info_gradient col-12 text-white ml-0 mr-auto"
            >Salva Modifiche</b-button
          >
          <b-button
            v-if="stato === 5"
            size="sm"
            @click="confirmRequest"
            type="button"
            variant="primary  text-white ml-0 mr-auto col-12 my-1"
            >Conferma Prenotazione</b-button
          >
          <b-button
            v-if="stato === 5"
            size="sm"
            @click="setAction('reject')"
            type="button"
            variant="outline-danger  mr-0 ml-auto col-12 my-1"
            >Rifiuta Modifiche</b-button
          >
          <b-button
            v-if="checkValidity"
            size="sm"
            @click="setAction('cancel')"
            type="button"
            variant="outline-danger  mr-0 col-12 my-1"
          >
            <b-spinner
              variant="primary"
              label="loading"
              v-if="loading"
            ></b-spinner>
            <span v-else> Annulla Prenotazione</span>
          </b-button>
        </div>
      </b-col>
    </b-row>

    <b-modal
      size="xl"
      hide-footer
      centered
      ref="modal-confirm"
      title="Conferma Annullamento"
    >
      <PickMessageForm
        :messages="cancellationMessages"
        confirm-message="Sei sicuro di voler annullare questo appuntamento?"
        @cancellation-confirmed="cancelRequest($event)"
        @refused-to-confirm="$bvModal.hide('modal-confirm')"
      />
    </b-modal>
    <b-modal
      ref="show-filleditem"
      hide-footer
      title="Questionario"
      size="xl"
      centered
    >
      <FilledSurveyShower v-if="survey" :response="survey.response" :score="survey.score" />
    </b-modal>
  </div>
</template>
<script>
import * as moment from "moment/moment"
import { supportService } from "@/_services"
import FilledSurveyShower from "@/components/attivita/FilledSurveyShower.vue"
import { CANCELLATION_MESSAGES_CITTADINO } from "@/_utils/constants.js"
import PickMessageForm from "@/components/prenotazioni/form/PickMessageForm.vue"

export default {
  name: "UserDetailTeleconsulto",
  props: [
    "detail",
    "stato",
    "datainizio",
    "quest",
    "hash",
    "suspendedSurvey",
    "company_notes"
  ],
  components: { FilledSurveyShower, PickMessageForm },
  data() {
    return {
      cancellationMessages: CANCELLATION_MESSAGES_CITTADINO,
      actionName: null,
      loading: false,
      allegati: null,
      survey: null,
      editBtn: false
    }
  },
  computed: {
    checkValidity: function () {
      var data = moment(this.datainizio)
      // console.log("data", data);
      return moment().isBefore(data)
    }
  },
  methods: {
    confirmRequest() {
      // console.log(this.detail.hashid);
      var data = {
        confirmed: true,
        action: "confirm"
      }
      this.$emit("update-request-status", data)
    },
    setAction(action) {
      this.actionName = action
      this.$refs["modal-confirm"].show()
    },
    cancelRequest(message) {
      this.loading = true
      var data = {
        confirmed: false,
        message,
        action: this.actionName
      }
      this.$refs["modal-confirm"].hide()
      this.$emit("update-request-status", data)
    },
    downloadOne() {
      var self = this
      supportService
        .downloadFilledSurvey(this.quest.hashid)
        .then(function (response) {
          let blob = new Blob([response.data])
          // let blob = new Blob([response.data], { type: "text/csv" });
          let link = document.createElement("a")
          link.href = window.URL.createObjectURL(blob)
          link.download = "teleconsulto_questionario.pdf"
          link.click()
        })
        .catch(function (err) {
          console.log(err)
          window.scrollTo(0, 0)
          self.errorAlert("Non è stato possibile recuperare il questionario")
        })
    }
  },
  watch: {
    quest(val) {
      if (val) {
        this.survey = val
      } else {
        this.survey = null
      }
    }
  },
  created() {
    if (this.quest) {
      this.survey = this.quest
    }
  }
}
</script>
<style lang="scss" scoped>
.simple__input {
  border-radius: 0 !important;
  font-size: 93% !important;
}

.simple__input:focus {
  box-shadow: none !important;
}

button {
  border-radius: 11px;
  max-width: 260px !important;
}
</style>
