<template>
  <div v-if="detail">
    <b-row id="detail-prodotto-row" align-v="stretch" class="mt-n4 flex-wrap">
      <b-col
        v-if="detail.nre"
        class="col-12 col-md-4 col-lg-3 col-xl-2 d-flex flex-column justify-content-start px-0"
      >
        <div class="m-0 p-0 detail-modal-card p-3 mr-4 mt-4 h-100">
          <p class="font-weight-bolder detail-modal-card-titles">
            RICETTA MEDICA
          </p>
          <p class="m-0 py-1">NRE: {{ detail.NRE }}</p>
          <p
            v-if="detail.available !== null"
            class="mb-0 py-1"
            :class="detail.available == 1 ? 'text-success' : 'text-danger'"
          >
            <span class="">
              I prodotti in ricetta
              {{
                detail.available == 1
                  ? "sono disponibili"
                  : "non sono disponibili"
              }}
            </span>
          </p>
        </div>
      </b-col>
      <b-col
        v-if="detail.rev"
        class="col-12 col-md-4 col-lg-3 col-xl-2 d-flex flex-column justify-content-start px-0"
      >
        <div class="m-0 p-0 detail-modal-card p-3 mr-4 mt-4 h-100">
          <p class="font-weight-bolder detail-modal-card-titles">
            RICETTA VETERINARIA
          </p>
          <p class="m-0 py-1">REV: {{ detail.rev }}</p>
          <p
            v-if="detail.available !== null"
            class="mb-0 py-1"
            :class="detail.available == 1 ? 'text-success' : 'text-danger'"
          >
            <span class="">
              I prodotti in ricetta
              {{
                detail.available == 1
                  ? "sono disponibili"
                  : "non sono disponibili"
              }}
            </span>
          </p>
          <p class="font-size-085rem py-1 m-0">
            <i
              >Ricorda di portare con te il pin della ricetta quando vai a
              ritirare l'ordine!</i
            >
          </p>
        </div>
      </b-col>
      <b-col
        v-if="detail.chart.length > 0"
        class="col-12 col-md-4 col-lg-3 col-xl-2 d-flex flex-column justify-content-start px-0"
      >
        <div class="m-0 p-0 detail-modal-card p-3 mr-4 mt-4 h-100">
          <p class="font-weight-bolder detail-modal-card-titles">
            PRODOTTI RICHIESTI
          </p>
          <div
            v-for="(item, ind) in detail.chart"
            :key="ind"
            :class="detail.chart.length < 2 ? 'mb-0' : 'mb-1'"
          >
            <p>
              {{ ind + 1 }}.
              <strong class="text-primary">{{ item.nome }}</strong>
            </p>
            <p class="pl-2">
              <b>
                {{ item.quantity }}
                {{ item.quantity > 1 ? "confezioni " : "confezione " }}</b
              >
            </p>
            <p class="pl-2" v-if="item.generic">- <b> GENERICO</b></p>
            <p class="pl-2" v-if="item.prescription_required !== null">
              Prescrizione
              <span class="underlined"
                >{{
                  item.prescription_required == 1
                    ? "necessaria"
                    : "non necessaria"
                }}
              </span>
            </p>
            <p class="pl-2" v-if="item.available !== null">
              Prodotto
              <span class="underlined"
                >{{ item.available == 1 ? "disponibile" : "non disponibile" }}
              </span>
            </p>
            <p class="pl-2" v-if="item.nre">
              NRE:
              <strong>{{ item.nre }} </strong>
            </p>
            <p class="pl-2" v-if="item.rev">
              REV:
              <strong>{{ item.rev }} </strong>
            </p>
          </div>
        </div>
      </b-col>
      <b-col
        v-if="detail.details"
        class="col-12 col-md-4 col-lg-3 col-xl-2 d-flex flex-column justify-content-start px-0"
      >
        <div class="m-0 p-0 detail-modal-card p-3 mr-4 mt-4 h-100">
          <p class="font-weight-bolder detail-modal-card-titles">
            NOTE SULL'ORDINE
          </p>
          <p class="m-0 p-0 text-capitalize">{{ detail.details }}</p>
        </div>
      </b-col>
      <b-col
        v-if="company_notes"
        class="col-12 col-md-4 col-lg-3 col-xl-2 d-flex flex-column justify-content-start px-0"
      >
        <div class="m-0 p-0 detail-modal-card p-3 mr-4 mt-4 h-100">
          <p class="font-weight-bolder detail-modal-card-titles">
            NOTE DELL'OPERATORE
          </p>
          <p>{{ company_notes }}</p>
        </div>
      </b-col>
      <b-col
        v-if="
          Math.abs(stato) !== 4 && (editBtn || stato === 5 || checkValidity)
        "
        class="col-12 col-md-4 col-lg-3 col-xl-2 d-flex flex-column justify-content-start px-0"
      >
        <div class="m-0 p-0 p-3 mr-4 mt-4 h-100">
          <b-button
            v-if="editBtn"
            size="sm"
            @click="updateRequest"
            type="button"
            variant="info info_gradient col-12 my-1 text-white ml-0 mr-auto"
            >Salva Modifiche</b-button
          >
          <b-button
            v-if="stato === 5"
            size="sm"
            @click="confirmRequest"
            type="button"
            variant="outline-primary col-12 my-1 ml-0 mr-auto"
            >Conferma Ordine</b-button
          >
          <b-button
            v-if="stato === 5"
            @click="setAction('reject')"
            size="sm"
            type="button"
            variant="outline-danger col-12 my-1 mr-0 ml-auto"
            >Rifiuta Modifiche</b-button
          >
          <b-button
            v-if="checkValidity"
            size="sm"
            @click="setAction('cancel')"
            type="button"
            variant="outline-danger col-12 my-1 mr-0 ml-auto"
          >
            <b-spinner
              variant="primary"
              label="loading"
              v-if="loading"
            ></b-spinner>
            <span v-else> Annulla Ordine</span>
          </b-button>
        </div>
      </b-col>
    </b-row>

    <div
      class="d-flex justify-content-center mb-2 flex-wrap"
      v-if="Math.abs(stato) !== 4"
    ></div>

    <b-modal
      size="xl"
      hide-footer
      centered
      ref="modal-confirm"
      title="Conferma Annullamento"
    >
      <PickMessageForm
        :messages="cancellationMessages"
        confirm-message="Sei sicuro di voler annullare questo appuntamento?"
        @cancellation-confirmed="cancelRequest($event)"
        @refused-to-confirm="$bvModal.hide('modal-confirm')"
      />
    </b-modal>
  </div>
</template>
<script>
import * as moment from "moment/moment"
import { reservationService } from "@/_services"
import PickMessageForm from "@/components/prenotazioni/form/PickMessageForm.vue"
import { CANCELLATION_MESSAGES_CITTADINO } from "@/_utils/constants.js"

export default {
  name: "UserDetailProdotto",
  props: ["dettaglio", "stato", "datainizio", "company_notes"],
  components: { PickMessageForm },

  data() {
    return {
      cancellationMessages: CANCELLATION_MESSAGES_CITTADINO,
      picLink: null,
      actionName: null,
      loading: false,
      detail: this.dettaglio,
      editBtn: false
    }
  },
  computed: {
    checkValidity: function () {
      if (this.stato !== 5) {
        var data = moment(this.datainizio)
        return moment().isBefore(data)
      }
      return false
    }
  },
  watch: {
    dettaglio: {
      handler(val) {
        this.$nextTick(function () {
          this.detail = val
          if (val.resource_id !== null) {
            this.getAllegato()
          }
        })
      },
      deep: true
    },
    picLink(val) {
      //
    }
  },
  methods: {
    getAllegato() {
      var self = this
      var endpoint =
        this.detail.allegato.full_path + "/" + this.detail.allegato.hashid
      reservationService
        .getAllegato(endpoint)
        .then(function (res) {
          self.picLink = window.URL.createObjectURL(new Blob([res.data]))
        })
        .catch(function (err) {
          console.log(err)
        })
    },
    confirmRequest() {
      // console.log(this.detail.hashid);
      var data = {
        confirmed: true,
        action: "confirm"
      }
      this.$emit("update-request-status", data)
    },
    setAction(action) {
      this.actionName = action
      this.$refs["modal-confirm"].show()
    },
    cancelRequest(message) {
      this.loading = true
      var data = {
        confirmed: false,
        message,
        action: this.actionName
      }
      this.$refs["modal-confirm"].hide()
      this.$emit("update-request-status", data)
    }
  },
  created() {
    // console.log("before destroy", this.dettaglio);
    if (this.detail && this.detail.allegato !== null) {
      this.getAllegato()
    }
  }
  // beforeDestroy() {
  //   console.log("before destroy", this.$refs.allegato);
  //   this.picLink = null;
  //   this.detail = null;
  //   if (this.$refs.allegato) {
  //     this.$refs.allegato.src = null;
  //   }
  // },
}
</script>
