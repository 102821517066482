<template>
  <div
    class="w-100 general_border bg-white py-2"
    style="position: relative"
    v-if="reservation"
  >
    <b-icon
      icon="x"
      font-scale="1.5"
      class="point"
      variant="darkgrey"
      @click="$bvModal.hide('modal-dettagliorec')"
      style="position: absolute; top: 7px; right: 7px; z-index: 100"
    ></b-icon>
    <div
      class="w-100 border-bottom border-primary d-flex flex-wrap mt-n2 pl-2 pr-4"
    >
      <section class="d-flex flex-grow-1 mt-2">
        <div class="p-0 mx-2 d-flex justify-content-center align-items-center">
          <b-icon
            class="d-xsm-none"
            font-scale="1.75"
            variant="primary"
            :icon="icons[reservation.icona]"
          ></b-icon>
          <b-icon
            class="d-none d-xsm-block"
            font-scale="2.25"
            variant="primary"
            :icon="icons[reservation.icona]"
          ></b-icon>
        </div>
        <b-container class="px-0 d-flex align-items-center" fluid>
          <b-row class="d-flex align-items-center">
            <b-col class="pl-0">
              <template v-if="reservation.orderable.prestazione">
                <h4 class="my-0 py-0 text-primary text-capitalize">
                  {{ reservation.orderable.prestazione.name }}
                </h4>
                <p class="my-0 text-capitalize">
                  {{ reservation.orderable.prestazione.description }}
                </p>
              </template>
              <template v-else-if="reservation.tipo_ordine === 'prodotto'">
                <h4 class="my-0 py-0 text-primary d-flex align-items-center">
                  Richiesta prodotti
                </h4>
              </template>
              <template v-else-if="reservation.tipo_ordine === 'teleconsulto'">
                <h4 class="ml-3 my-0 text-primary align-items-center">
                  Teleconsulto
                </h4>
              </template>
            </b-col>
          </b-row>
        </b-container>
      </section>

      <div class="d-flex justify-content-center align-items-center mx-auto">
        <div class="my-0 d-flex flex-column align-items-start text-center">
          <p class="d-flex pt-4 text-center">
            <span
              class="font-weight-bolder"
              :class="'text-' + statusModalVariants[reservation.stato]"
            >
              {{ reservation.stato }}
            </span>
          </p>
        </div>
      </div>
    </div>

    <b-container
      id="detail-cittadino-body"
      class="py-0 pb-4 border-bottom border-primary"
      fluid
    >
      <b-row cols="1">
        <b-col class="px-1 px-sm-4 py-0 d-flex flex-column">
          <b-row class="flex-wrap">
            <b-col
              class="px-0"
              v-if="
                reservation.requested_by.anagrafica.cf !==
                reservation.subjectable.anagrafica.cf
              "
            >
              <p class="text-primary text-left mb-0 py-3">Per conto di</p>
              <section
                v-if="
                  reservation.requested_by.anagrafica.cf !==
                  reservation.subjectable.anagrafica.cf
                "
                class="mb-2 detail-modal-card p-3 text-left reservation-info-container mr-4"
              >
                <div class="m-0 mb-2 p-0 d-flex flex-wrap mt-n3">
                  <div class="m-0 ml-2 p-0 mr-4 mt-3">
                    <p class="mb-1 font-weight-bolder detail-modal-card-titles">
                      NOME E COGNOME
                    </p>
                    <p class="m-0 p-0 text-capitalize">
                      <span v-if="reservation.subjectable.anagrafica">
                        {{ reservation.subjectable.anagrafica.name }}
                        {{ reservation.subjectable.anagrafica.surname }}
                      </span>

                      <span v-else>
                        {{ reservation.subjectable.anagrafica.name }}
                        {{ reservation.subjectable.anagrafica.surname }}
                      </span>
                    </p>
                  </div>
                  <div class="m-0 ml-2 p-0 mt-3">
                    <p class="mb-1 font-weight-bolder detail-modal-card-titles">
                      CODICE FISCALE
                    </p>
                    <p class="anagraphic-data-text mb-1 mg-lg-2">
                      {{
                        reservation.subjectable.anagrafica
                          ? reservation.subjectable.anagrafica.cf
                          : reservation.subjectable.cf
                      }}
                    </p>
                  </div>
                </div>
              </section>
            </b-col>
            <b-col class="px-0">
              <p class="text-primary mb-0 py-3 text-left">Prenotazione</p>
              <section
                class="mb-2 mr-4 detail-modal-card p-3 text-left d-flex flex-wrap reservation-info-container"
              >
                <div class="m-0 mb-2 p-0 pb-1 d-flex mr-4">
                  <div class="m-0 ml-2 p-0">
                    <p
                      class="mb-1 font-weight-bolder detail-modal-card-titles text-left"
                    >
                      DATA E ORA
                    </p>
                    <p
                      class="anagraphic-data-text mb-1 mg-lg-2 text-capitalize"
                    >
                      {{ reservation.data_inizio | moment("DD.MM.YY HH:mm") }}
                    </p>
                  </div>
                </div>

                <div class="m-0 mb-2 p-0 d-flex mr-4">
                  <div class="m-0 ml-2 p-0">
                    <p
                      class="mb-1 font-weight-bolder detail-modal-card-titles text-left"
                    >
                      PRESSO
                    </p>
                    <p class="m-0 p-0 text-capitalize anagraphic-data-text">
                      {{ reservation.company.ff_denominazione }},
                      {{ reservation.company.indirizzo }}
                    </p>
                  </div>
                </div>
                <div class="m-0 mb-2 p-0 d-flex mr-4">
                  <div class="m-0 ml-2 p-0">
                    <p
                      class="mb-1 font-weight-bolder detail-modal-card-titles text-left"
                    >
                      CODICE RICHIESTA
                    </p>
                    <p class="m-0 p-0 text-capitalize anagraphic-data-text">
                      #{{ reservation.codice_richiesta }}
                    </p>
                  </div>
                </div>
              </section>
            </b-col>
          </b-row>
        </b-col>
        <b-col class="col-12 px-1 px-sm-4 py-0 d-flex flex-column">
          <p class="text-primary mb-0 py-3 text-left">Dettagli</p>
          <section>
            <UserDetailTeleconsulto
              :company_notes="reservation.company_notes"
              :detail="reservation.orderable"
              :stato="reservation.status"
              :suspendedSurvey="reservation.suspended_survey"
              :datainizio="reservation.data_inizio"
              :quest="reservation.filled_survey"
              :hash="reservation.hashid"
              v-if="reservation.tipo_ordine === 'teleconsulto'"
              @update-request-status="emitHandleStatusUpdate($event)"
            />
            <UserDetailPrestazione
              :company_notes="reservation.company_notes"
              :suspendedSurvey="reservation.suspended_survey"
              :detail="reservation.orderable"
              :stato="reservation.status"
              :datainizio="reservation.data_inizio"
              :quest="reservation.filled_survey"
              v-if="reservation.tipo_ordine === 'prestazione'"
              @update-request-status="emitHandleStatusUpdate($event)"
            />
            <UserDetailProdotto
              :company_notes="reservation.company_notes"
              :dettaglio="reservation.orderable"
              :stato="reservation.status"
              :datainizio="reservation.data_inizio"
              v-if="reservation.tipo_ordine === 'prodotto'"
              @update-request-status="emitHandleStatusUpdate($event)"
            />
          </section>
        </b-col>
      </b-row>
    </b-container>

    <div class="d-flex justify-content-start mx-3 pt-3 flex-wrap">
      <div class="m-0 mb-2 mr-3 p-0 d-flex">
        <div class="m-0 ml-1 p-0">
          <p class="m-0 p-0 font-weight-bolder m-0 detail-modal-card-titles">
            DATA OPERAZIONE
          </p>
          <p class="m-0 p-0 text-capitalize">
            {{ reservation.created_at || moment("DD.MM.YY HH:mm") }}
          </p>
        </div>
      </div>
      <div class="m-0 mb-2 mr-3 p-0 d-flex">
        <div class="m-0 ml-1 p-0">
          <p
            class="m-0 p-0 font-weight-bolder m-0 detail-modal-card-titles text-left"
          >
            LE TUE NOTE
            <UpdateNotesComponent
              @note-updated="emitHandleNotesUpdate($event)"
              class="d__innline"
              :hashid="reservation.hashid"
              :reserdate="reservation.data_inizio"
            >
            </UpdateNotesComponent>
          </p>
          <p class="m-0 p-0" v-if="reservation.user_notes">
            {{ reservation.user_notes }}
          </p>
        </div>
      </div>
      <div class="justify-self-end mr-0 ml-auto align-self-center mt-n3 pb-3">
        <ChatComponent
          :stillActive="reservation.status > 0"
          :chatChannel="reservation.hashid"
          :recipient="{
            type: 'company',
            hashid: reservation.company.hashid
          }"
          class="d-inline-block mx-1 mt-3"
          :expirationDate="reservation.data_inizio"
          @read-messages="emitFetchData()"
        ></ChatComponent>
        <UpdateReservationDate
          class="d-inline-block mx-1 mt-3"
          :hash="reservation.hashid"
          @change-requested="emitHandleUpdateRequest()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import UserDetailPrestazione from "@/components/prenotazioni/dettaglio/UserDetailPrestazione.vue"
import UserDetailTeleconsulto from "@/components/prenotazioni/dettaglio/UserDetailTeleconsulto.vue"
import UserDetailProdotto from "@/components/prenotazioni/dettaglio/UserDetailProdotto.vue"
import UpdateNotesComponent from "@/components/prenotazioni/dettaglio/UpdateNotesComponent.vue"
import UpdateReservationDate from "@/components/prenotazioni/dettaglio/UpdateReservationDate.vue"
import ChatComponent from "@/components/prenotazioni/dettaglio/ChatComponent.vue"
export default {
  name: "DettaglioPrenotazioneCittadinoComponent",
  props: ["reservation"],
  components: {
    UserDetailPrestazione,
    UserDetailTeleconsulto,
    UserDetailProdotto,
    UpdateNotesComponent,
    UpdateReservationDate,
    ChatComponent
  },
  data() {
    return {
      statusModalIcons: {
        confermato: "check-circle",
        ricevuto: "archive",
        annullato: "x-circle",
        rifiutato: "x-circle",
        "in attesa di risposta": "question-circle",
        evaso: "calendar2-check"
      },
      statusModalVariants: {
        confermato: "secondary",
        ricevuto: "primary",
        annullato: "danger",
        rifiutato: "danger",
        "in attesa di risposta": "warning",
        evaso: "primary"
      },
      icons: {
        0: "bag",
        1: "camera-video",
        2: "file-ppt",
        3: "chat-text",
        4: "screwdriver",
        5: "droplet-fill",
        6: "flower1",
        7: "emoji-smile",
        8: "exclude",
        9: "egg-fried",
        10: "gem",
        11: "heart-fill",
        12: "hourglass-split",
        13: "journal-medical",
        14: "life-preserver",
        15: "lock-fill",
        16: "moon",
        17: "node-minus-fill",
        18: "x-diamond-fill",
        19: "cart-fill",
        20: "bug-fill",
        21: "brightness-high-fill",
        22: "bell-fill"
      }
    }
  },
  created() { },
  methods: {
    emitHandleStatusUpdate(statusData) {
      this.$emit("handle-status-update", statusData)
    },
    emitHandleNotesUpdate(notesData) {
      this.$emit("handle-notes-update", notesData)
    },
    emitFetchData() {
      this.$emit("fetch-data")
    },
    emitHandleUpdateRequest() {
      this.$emit("handle-update-request")
    }
  },
  computed: {}
}
</script>
<style scoped>
#detail-cittadino-body {
  overflow: scroll;
  max-height: 485px;
}

.reservation-info-container {
  max-width: 700px;
}

@media (max-width: 900px) {
  .reservation-info-container {
    min-width: 90%;
  }
}

@media (max-width: 1000px) {
  .reservation-info-container {
    min-width: 70%;
  }
}

@media (max-width: 1200px) {
  .reservation-info-container {
    min-width: 60%;
  }
}

@media (max-width: 1400px) {
  .reservation-info-container {
    min-width: 50%;
  }
}

@media (max-width: 1600px) {
  .reservation-info-container {
    min-width: 40%;
  }
}

@media (min-width: 1601px) {
  .reservation-info-container {
    min-width: 30%;
  }
}
</style>
